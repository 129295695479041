import get from 'lodash/get'
import { toBase64 } from '../utils/base64'
/**
 * query for stock information by productHandle. cache for 300 seconds.
 */
export const getCollectionProductStock = (env) => {
  const MYSHOPIFY_SUBDOMAIN = (env) ? env.MYSHOPIFY_SUBDOMAIN : process.env.MYSHOPIFY_SUBDOMAIN
  return {
    url: `/api/v1/gql-proxy.json`,
    ttl: 300,
    endpoint: `https://${MYSHOPIFY_SUBDOMAIN}.myshopify.com/admin/api/2021-10/graphql.json`,
    query: `query GetCollectionProductStockLevels($collectionHandle: String!) {
      collectionByHandle(handle: $collectionHandle) {
        id
        handle
        products(first: 50) {
          edges {
            node {
              handle
              variants(first: 10) {
                edges { node { id inventoryQuantity availableForSale } }
              }
            }
          }
        }
      }
    }`,
    /**
     * map the gql response to a more usable format. the return value of this
     * function is cached in dynamo.
     */
    mapResponse: (response) => {
      let productsMap = {}
      const productEdges = get(response, 'data.data.collectionByHandle.products.edges') || []
      for (let edge of productEdges) {
        let variantsMap = {}
        const variantEdges = get(edge, 'node.variants.edges') || []
        const handle = get(edge, 'node.handle')
        for (let { node: { availableForSale, id, inventoryQuantity } } of variantEdges) {
          const variantId = toBase64(id)
          variantsMap[variantId] = {
            availableForSale,
            id: variantId,
            inventoryQuantity,
          }
        }
        productsMap[handle] = variantsMap
      }
      return productsMap
    }
  }
}
