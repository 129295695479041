import { dataWrapper, readCache } from './utils'

const DEFAULT_LOCALE = 'en-us'

const contentDataWrapper = ($nacelle, method, options) => dataWrapper($nacelle.contentData, method, options)

/**
 * @param {object} options
 * @param {object} options.$nacelle
 */
export const getPage = (options) => {
  const { $nacelle, handle } = options
  const locale = options.locale || $nacelle.locale || DEFAULT_LOCALE
  if (
    handle &&
    (handle !== 'null') &&
    (handle !== 'undefined')
  ) {
    return contentDataWrapper($nacelle, 'page', { handle, locale })
  }
}

export const getBlog = (options) => {
  const { $nacelle, handle } = options
  const locale = options.locale || $nacelle.locale || DEFAULT_LOCALE
  return contentDataWrapper($nacelle, 'blog', { handle, locale })
}

export const getArticle = (options) => {
  const { blogHandle, handle, $nacelle } = options
  const locale = options.locale || $nacelle.locale || DEFAULT_LOCALE
  if (process.server || (process.env.NODE_ENV === 'test')) {
    return readCache(`articles/${blogHandle}`, {
      handle,
      locale
    })
  } else {
    return $nacelle.data.article({
      handle,
      blogHandle,
      locale
    })
  }
}

/**
 * @param {object} options
 * @param {object} options.blog
 * @param {string} options.locale
 * @param {string} options.selectedList
 * @param {number} options.startPoint
 */
export const getBlogPage = async (options) => {
  const { blog, $nacelle, selectedList = 'default', paginate, itemsPerPage, startPoint } = options
  const locale = options.locale || $nacelle.locale || DEFAULT_LOCALE
  if (process.server || (process.env.NODE_ENV === 'test')) {
    if (
      blog &&
      blog.articleLists &&
      blog.articleLists.length > 0
    ) {
      const articleList = blog.articleLists.find(list => {
        return list.slug === selectedList
      })
      const handles = articleList.handles.slice(
        startPoint,
        startPoint + itemsPerPage || 30
      )
      const articles = await Promise.all(
        handles.map((handle) =>
          getArticle({
            blogHandle: blog.handle,
            handle,
            locale,
            $nacelle
          })
        )
      )
      return articles.filter((v) => !!v)
    }
  } else {
    if (
      blog &&
      blog.articleLists &&
      blog.articleLists.length > 0
    ) {
      const articleList = blog.articleLists.find(list => {
        return list.slug === selectedList
      })
      const handles = articleList.handles.slice(
        startPoint,
        startPoint + itemsPerPage || 12
      )
      const articles = await Promise.all(
        handles.map((handle) =>
          getArticle({
            blogHandle: blog.handle,
            handle,
            locale,
            $nacelle
          })
        )
      )
      return articles.filter((v) => !!v)
    }
  }
}

/**
 * @param {object} options
 * @param {object} options.$nacelle
 * @param {string} options.handle
 * @param {string} options.type
 * @param {string} [options.locale]
 */
export const getContentItem = async (options) => {
  const { $nacelle, handle, type } = options
  const locale = options.locale || $nacelle.locale || DEFAULT_LOCALE
  return contentDataWrapper($nacelle, 'content', {
    handle,
    locale,
    type
  })
}
