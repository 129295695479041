import { joinURL } from 'ufo'

export function getImage(src, { modifiers, baseURL } = {}, { options, nuxtContext, $img }) {
  const { width, height, quality, format, fit, ...providerModifiers } = modifiers
  const operations = [
    `&width=${width}`
  ]
  const operationsString = operations.join(',')
  return {
    url: joinURL(baseURL, src, operationsString)
  }
}