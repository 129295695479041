// this file is used in a serverless function, so we can't use aliases here
import { mapProduct } from '@/utils/shopifyHelper'
import { readCache, isValidHandle, ENABLE_NACELLE_CACHE } from './utils'

/**
 * refactored out of ~/mixins/getCollection to support more dynamic use cases
 *
 * @param {object} options - options object
 * @param {object} options.$nacelle - nacelle sdk
 * @param {string} options.handle - collection handle
 * @param {string} [options.locale] - current locale
 */
export const getProduct = async ({ $nacelle, handle, locale }) => {
  locale = locale || $nacelle.locale
  let result = null
  if (
    isValidHandle(handle)
  ) {
    try {
      if (ENABLE_NACELLE_CACHE) {
        result = await readCache('product', { handle, locale })
      } else {
        result = await $nacelle.data.product({
          handle,
          locale
        })
      }
      result = (result) ? mapProduct(result) : result
      if (result.handle === '') {
        throw new Error('InvalidNacelleData', { cause: result })
      }
    } catch (err) {
      err.cause = handle
      if (
        ['test', 'development'].includes(process.env.NODE_ENV)
      ) {
        console.warn('getProduct', err, err.cause)
      }
      throw err
    }
  }
  return result
}
