import EngravingScreen from '~/components/screens/EngravingScreen'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
export default {
  components: {
    EngravingScreen
  },
  computed: {
    ...mapGetters('engraving', {
      engravingContext: 'context',
      showEngravingScreen: 'engravingVisible',
    }),
    ...mapState('cart', ['lineItems', 'engravingFeeProduct']),
  },
  methods: {
    ...mapActions('engraving', {
      closeEngraving: 'close',
      addEngravingToCart: 'addEngraving'
    }),
    ...mapMutations('braceletBuilder', {
      setModLineItemProps: 'setLineItemProps'
    }),
    handleAddEngraving(payload) {
      const context = this.engravingContext
      let contextMode = null
      // FIXME type of this.engravingContext changed, removing the `mode` property.
      // mode should be a property of the context object. multiple modes per engraving
      // trasaction is not supported so we shouldn't be passing a mode for every
      // item.
      console.log('handleAddEngraving mode', { mode: context.mode, payload, context })
      for (let { mode } of (context || [])) { // set a mode for this transaction as a workaround
        if (mode) {
          contextMode = mode
          break
        }
      }
      if (
        (context && (context.mode === 'mod')) ||
        (contextMode === 'mod')
      ) {
        const { props } = payload
        this.setModLineItemProps(props)
        this.closeEngraving()
      } else {
        this.addEngravingToCart(payload)
      }
    }
  }
}
