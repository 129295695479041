import isString from 'lodash/isString'
export const ENABLE_NACELLE_CACHE = (
  (process.server && !process.env.DISABLE_NACELLE_CACHE) ||
  (process.env.NODE_ENV === 'test')
)
export const isValidHandle = (handle) => (
  isString(handle) &&
  handle &&
  (handle !== 'null') &&
  (handle !== 'undefined')
)
/**
 * async wrapper around fs.readFile
 * @param {string} fileName
 */
export const readFileAsync = fileName => {
  const fs = require('fs')
  return new Promise((resolve, reject) => {
    fs.readFile(fileName, (err, data) => {
      err ? reject(err) : resolve(data)
    })
  })
}
/**
 * read cached json data, for test purposes. the sdk response is way too
 * slow.
 */
export const readCache = async (db, {
  handle,
  locale,
  type,
}) => {
  const dbMap = {
    collection: 'collections',
    product: 'products',
    blog: 'blogs',
  }
  let result = null
  try {
    const prefix = `./static/data/${dbMap[db] || db}`
    let keys = [ handle, locale ]
    if (type) {
      keys.push(type)
    }
    const itemKey = keys.join('--')
    const file = await readFileAsync(`${prefix}/${itemKey}/static.json`)
    result = JSON.parse(file)
  } catch (err) {
    console.error('CACHE MISS', { handle, type, locale, err })
    throw new Error(`CacheMiss<${db}/${handle}>`)
  }
  return result
}
/**
 * @param {object} $nacelle
 * @param {string} method
 * @param {object} sdkOptions
 */
export const dataWrapper = async (
  $nacelleData,
  method,
  { handle, locale, type }
) => {
  let result = null
  try {
    if (process.env.NODE_ENV === 'test') {
      result = await readCache(method, { handle, type, locale })
    } else {
      result = await $nacelleData[method]({ handle, type, locale })
    }
  } catch (err) {
    if (
      ['test', 'development'].includes(process.env.NODE_ENV)
    ) {
      console.warn(`dataWrapper<${method}>`, handle, locale, type, err)
    }
    throw err
  }
  return result
}
