import BxGy from '~/utils/BxGy'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import sleep from '~/utils/sleep'
import uniq from 'lodash/uniq'
import { setFlag } from '~/utils/userFlags'
import { engravingKeys, feeHandles } from '~/utils/cartHelper'
/**
 * @param {object} options
 * @param {object[]} options.lineItems
 * @param {string} options.handle
 */
export const getLineItemIdByHandle = ({ lineItems, handle }) => {
  let id
  for (let lineItem of lineItems) {
    if (lineItem.handle === handle) {
      id = lineItem.id
      break
    }
  }
  return id
}
/**
 * @param {object} options
 * @param {object[]} options.lineItems
 * @param {string} options.id
 */
export const getLineItemById = ({ lineItems, id }) => {
  let result
  for (let lineItem of lineItems) {
    if (lineItem.id === id) {
      result = lineItem
      break
    }
  }
  return result
}
/**
 * @param {object} options
 * @param {string[]} options.engravingKeys
 * @param {object[]} options.lineItems
 * @param {string} options.id
 */
export const lineItemHasProps = ({ item, keys }) => {
  let result = null
  if (item && isArray(item.customAttributes) && isArray(keys)) {
    result = []
    for (let { key } of item.customAttributes) {
      if (keys.includes(key)) {
        result.push(true)
      }
    }
  }
  return result ? (result.length === keys.length) : false
}
/**
 * @param {object} options
 * @param {object[]} options.lineItems
 * @param {string} options.id
 */
const lineItemHasBraceletSleeve = (lineItem) => {
  // console.log('has bracelet', lineItem.handle, lineItem.customAttributes)
  let result = false
  if (
    lineItem &&
    lineItem.handle &&
    lineItem.handle.includes('bracelet') &&
    isArray(lineItem.customAttributes)
  ) {
    for (let { key } of lineItem.customAttributes) {
      if (key === 'sleeve') {
        result = true
      }
    }
  }
  return result
}
// cached fee products
let feeProductCache = {}
/**
 */
const updateFees = async (store, feeHandle, feeItem, feeQuantity, engravingDiscount) => {
  // console.log('[cart-manager]', feeHandle, !!feeItem, feeQuantity)
  if ((feeQuantity === 0) && feeItem) {
    store.commit('cart/removeLineItemMutation', feeItem)
  } else if (feeQuantity > 0) {
    if (feeItem) {
      // set quantity if needed
      store.commit('cart/setQuantityMutation', {
        id: feeItem.id,
        quantity: feeQuantity
      })
    } else {
      // add fee to cart with quantity
      if (!feeProductCache[feeHandle]) {
        let product = await store.$nacelle.data.product({
          handle: feeHandle
        })
        feeProductCache[feeHandle] = {
          ...product,
          variant: get(product, 'variants[0]'),
        }
      }
      let feeObj;
      if (!engravingDiscount) {
        feeObj = {
          ...feeProductCache[feeHandle],
          props: [],
          quantity: feeQuantity
        }
      } else {
        feeObj = {
          ...feeProductCache[feeHandle],
          customAttributes: [{ key: '_engravingDiscount', value: engravingDiscount.toString()}],
          props: [],
          quantity: feeQuantity
        }
      }
      await store.dispatch('cart/addLineItem', feeObj)
    }
  }
}
/**
 * loop over the line items in the cart and determine which fees (if any) apply
 * and update the cart to match
 *
 * @param {object} store
 * @param {string} [type]
 * @param {string} handle
 */
const applyFees = async (store, type, handle) => {
  // console.log('[cart-manager]', { type, handle, feeHandles })
  if (feeHandles.includes(handle)) {
    return
  }
  const lineItems = get(store, 'state.cart.lineItems')

  let engravingFeeItem = false
  let engravingFeeDiscountItem = false
  let braceletFeeItem = false
  let engravingQuantity = 0
  let engravingDiscountQuanity = 0
  let engravingDiscount = 0
  let braceletQuantity = 0
  for (let item of lineItems) {
    if (lineItemHasProps({ item, keys: engravingKeys })) {
      if (lineItemHasProps({ item, keys: ["_engravingDiscount"] })) {
        engravingDiscountQuanity += item.quantity || 1
        if (item.customAttributes) {
          engravingDiscount = item.customAttributes.find((obj)=>{
            return obj.key == "_engravingDiscount"
          }).value
        }

      } else {
        engravingQuantity += item.quantity || 1
      }

    }
    if (lineItemHasBraceletSleeve(item) && !item.handle.includes("star-wars-mod")) {
      braceletQuantity += item.quantity || 1
    }
    if (item.handle === 'engraving-fee') {
      // discount item here
      if (item.customAttributes) {
        engravingFeeDiscountItem = item
      } else {
        engravingFeeItem = item
      }

    }
    if (item.handle === 'bracelet-sleeve-fee') {
      braceletFeeItem = item
    }
  }
  try {
    await updateFees(store, 'engraving-fee', engravingFeeItem, engravingQuantity)
    await updateFees(store, 'engraving-fee', engravingFeeDiscountItem, engravingDiscountQuanity, engravingDiscount)
    await updateFees(store, 'bracelet-sleeve-fee', braceletFeeItem, braceletQuantity)
  } catch (err) {
    console.error(err)
  }
}
// cached product handle lists
let bogoCollectionsCache = {}
const fetchCollectionHandles = async (store, handle) => {
  if (!bogoCollectionsCache[handle]) {
    try {
      const collection = await store.$nacelle.data.collection({
        handle
      })
      for (let productList of collection.productLists) {
        if (productList.slug === 'default') {
          bogoCollectionsCache[handle] = productList.handles
          break
        }
      }
    } catch (err) {
      console.error(err)
    }
  }
  return [...bogoCollectionsCache[handle]]
}
/**
 * @param {object} store
 * @param {string} type
 */
const applyBogo = async (store, type) => {
  const {
    bogo,
    bogoCustomerBuysCollections,
    bogoCustomerGetsCollections,
    bogoCustomerBuysQuantity,
    bogoCustomerBuysThreshold,
    bogoCustomerGetsQuantity,
    bogoCustomerGetsDiscountDecimal,
    bogoUsesPerOrderLimit,
    lineItems,
  } = store.state.cart
  const feedbackTypes = [
    'cart/removeLineItemProperties',
    'cart/setLineItemProperty',
  ]
  if (bogo && !feedbackTypes.includes(type)) {
    let buysHandles = []
    let getsHandles = []
    for (let handle of bogoCustomerBuysCollections) {
      const handles = await fetchCollectionHandles(store, handle)
      if (handles && handles.length) {
        buysHandles.push(...handles)
      }
    }
    for (let handle of bogoCustomerGetsCollections) {
      const handles = await fetchCollectionHandles(store, handle)
      if (handles && handles.length) {
        getsHandles.push(...handles)
      }
    }
    // remove bogo labels before we compute anything
    for (let { id, customAttributes } of lineItems) {
      if (customAttributes && customAttributes.length) {
        store.commit('cart/removeLineItemProperties', {
          id,
          keys: ['_local_bogo']
        })
      }
    }
    await sleep(1)
    const bxgy = new BxGy({
      buysHandles: uniq(buysHandles),
      buysQuantity: bogoCustomerBuysQuantity,
      buysThreshold: bogoCustomerBuysThreshold,
      getsDiscountDecimal: bogoCustomerGetsDiscountDecimal,
      getsQuantity: bogoCustomerGetsQuantity,
      getsHandles: uniq(getsHandles),
      lineItems,
      usesLimit: bogoUsesPerOrderLimit,
    })
    for (let item of bxgy.result) {
      if (item.hasBogoDiscount) {
        // set a local BOGO flag on the line item
        // console.log('bogo', item)
        try {
          store.commit('cart/setLineItemProperty', {
            id: item.id,
            prop: item.prop
          })
          await sleep(1)
        } catch (err) {
          console.error(err)
        }
      }
    }
  }
}
const getOptionsFromPayload = (type, payload) => {
  let result = null
  switch (type) {
  case 'cart/setLineItems':
    result = [type]
    break
  case 'cart/addLineItemMutation':
    result = [type, get(payload, 'handle')]
    break
  case 'cart/removeLineItemMutation':
    result = [type, get(payload, 'handle')]
    break
  case 'cart/incrementLineItemMutation':
    result = [type]
    break
  case 'cart/decrementLineItemMutation':
    result = [type]
    break
  case 'cart/addLineItemWithProps':
    result = [type, get(payload, 'item.handle')]
    break
  case 'cart/setLineItemProperty':
    result = [type]
    break
  case 'cart/removeLineItemProperties':
    result = [type]
    break
  }
  return result
}
/**
 * everything can run normally, plugin comes in after the fact to sanity check
 * and correct the line items. the goal here is to manage the complexity of
 * the cart without multiplying the problems complexity in the process.
 */
export const cartManagerPlugin = (store) => {
  /**
   * add/remove line item
   *   apply change
   *   do fees apply to the cart items?
   *     applyFees
   *   do limits apply for any cart items?
   *     inc/dec quantity
   * inc/dec line item
   *   apply change
   *   do fees apply to the cart items?
   *     applyFees
   *   do limits apply for any cart items?
   *     inc/dec quantity
   */
  const cartHandler = async (mutation) => {
    const { type, payload } = mutation
    // console.log('cartManager cartHandler', { type, payload })
    if (type === 'events/removeFromCart') {
      // handle gwp auto condition here because the cart/remove mutation doesn't
      // have the full line item object in it
      const item = get(payload, 'product')
      if (item) {
        const hasGwpAutoCondition = lineItemHasProps({
          item,
          keys: [ '_lineGiftWithPurchase', '_gwpauto' ]
        })
        if (hasGwpAutoCondition) {
          await setFlag('gwpauto-removed')
        }
      }
    }
    if (type.startsWith('cart/')) {
      const options = getOptionsFromPayload(type, payload)
      if (options) {
        // if you need to add a job that runs when the cart chages, add the
        // function to this list:
        const jobs = [applyFees, applyBogo]
        // run jobs serially, doing this in a Promise.all (concurrently) is just
        // asking for confusion.
        for (let job of jobs) {
          await job(store, ...options)
        }
      }
    }
  }
  if (process.client || (process.env.NODE_ENV === 'test')) {
    store.subscribe(cartHandler)
  }
}
