import { getPageType } from '~/utils/urlHelper'
export default ({ app, store }) => {
  if (process.client) {
    app.router.afterEach(async (to) => {
      try {
        await store.dispatch('events/pageView', {
          pageType: getPageType({ routeName: to.name }),
          params: to.params,
          path: to.fullPath,
          routeName: to.name,
          url: window.location.href,
        })
      } catch (err) {
        console.error(err)
      }
    })
  }
}
