import get from 'lodash/get'
import { toBase64 } from '../utils/base64'
/**
 * query for stock information by productHandle. cache for 300 seconds.
 */
export const getProductVariantStock = (env) => {
  const MYSHOPIFY_SUBDOMAIN = (env) ? env.MYSHOPIFY_SUBDOMAIN : process.env.MYSHOPIFY_SUBDOMAIN
  return {
    url: `/api/v1/gql-proxy.json`,
    ttl: 300,
    endpoint: `https://${MYSHOPIFY_SUBDOMAIN}.myshopify.com/admin/api/2021-10/graphql.json`,
    query: `query GetProductStockLevels($productHandle: String!) {
      productByHandle(handle: $productHandle) {
        id
        handle
        variants(first: 50) {
          edges { node { id inventoryQuantity availableForSale } }
        }
      }
    }`,
    /**
     * map the gql response to a more usable format. the return value of this
     * function is cached in dynamo.
     */
    mapResponse: (response) => {
      let variantsMap = {}
      const variantEdges = get(response, 'data.data.productByHandle.variants.edges') || []
      for (let { node: { availableForSale, id, inventoryQuantity } } of variantEdges) {
        const variantId = toBase64(id)
        variantsMap[variantId] = {
          availableForSale,
          id: variantId,
          inventoryQuantity,
        }
      }
      return variantsMap
    }
  }
}
