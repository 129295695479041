import get from 'lodash/get'
import isString from 'lodash/isString'
import some from 'lodash/some'
import { Product, PublicConfig } from '~/queries/schemas'
export const compileRegex = (ps: Array<string>): Array<RegExp> => ps.map(p => new RegExp(p))
/**
 * exclude products by handle or regex pattern.
 */
export const isHiddenProduct = (
  productOrHandle: Product | string,
  $config: PublicConfig
): boolean => {
  const { HIDDEN_PRODUCTS, drtv } = $config
  const productHandle = (isString(productOrHandle)) ? productOrHandle : get(productOrHandle, 'handle')
  let result = false
  if (drtv && drtv.enabled) {
    result = !drtv.products.includes(productHandle)
  } else {
    const excludePatterns = compileRegex(get($config, 'excludePatterns', []))
    const isExcludedPrefix = (handle: string): boolean => {
      return some(excludePatterns, (pattern: RegExp): boolean => pattern.test(handle))
    }
    result = (
      HIDDEN_PRODUCTS.includes(productHandle) ||
      isExcludedPrefix(productHandle)
    )
  }
  return result
}
/**
 ** utils
 **/

// reads specially formatted environment variables. hopefully makes them stand
// out when editing in vercel.
export const getMetafieldItems = (key: string): Array<string> => {
  const values = get(process.env, key)
  let items: Array<string> = []
  if (values && values.length && values.split) {
    items = values.split(',').map((i) => i.trim())
  }
  console.log('[meta]', key, items)
  return items
}
export const enabledFlag = (v: string | number | undefined) => {
  try {
    switch (typeof v) {
      case 'string':
        return (v == '1') || /yes|true/i.test(v)
      case 'number':
        return v === 1
      default:
        return false
    }
  } catch (err) {
    return false
  }
}
export const isHiddenCollection = (
  collectionHandle: string,
  $config: PublicConfig
): boolean => {
  const { HIDDEN_COLLECTIONS, drtv } = $config
  let result = false
  if (drtv && drtv.enabled) {
    result = !drtv.collections.includes(collectionHandle)
  } else {
    const excludePatterns = compileRegex(get($config, 'excludeCollectionPatterns', []))
    const isExcludedPrefix = (handle: string) => some(excludePatterns, (pattern) => pattern.test(handle))
    result = (
      HIDDEN_COLLECTIONS.includes(collectionHandle) ||
      isExcludedPrefix(collectionHandle)
    )
  }
  return result
}
/**
 * type safe version of the method used to parse urls in filterRoutes
 */
const ifHandleMatches = (prefix: string, fn: Function, path: string): boolean => {
  let result = false
  let handle = path.split(prefix).pop()
  if (handle) {
    handle = handle.split('/').shift()
  }
  if (handle) {
    result = fn(handle)
  }
  return result
}
export const filterRoutes = ({ $config, routes, }: {
  $config: PublicConfig;
  routes: Array<{ route: string }>;
}) => {
  return routes.filter(({ route: path }) => {
    if (path.startsWith('/products')) {
      return ifHandleMatches(
        '/products/',
         (handle: string) => !isHiddenProduct(handle, $config),
         path
      )
    } else if (path.startsWith('/collections')) {
      return ifHandleMatches(
        '/collections/',
        (handle: string) => !isHiddenCollection(handle, $config),
        path
      )
    } else if (
      path.startsWith('/content') ||
      path.startsWith('/shop')
    ) {
      return false
    } else if ($config.drtv && $config.drtv.enabled) {
      // omit on drtv build
      if (path.startsWith('/pages')) {
        return ifHandleMatches('/pages/', (handle: string) => $config.drtv.pages.includes(handle), path)
      } else if (path.startsWith('/blog')) {
        return false
      }
    } else {
      return true
    }
  })
}
