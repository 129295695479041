export const state = () => ({
  atcBtn: {
    default: true,
    variant1: false,
  },
  mobileNav: {
    default: true,
    variant1: false,
  },
  productInfoType: {
    default: true,
    variant1: false,
    variant2: false,
    variant3: false,
    variant4: false,
  },
  galleryImage: {
    default: true,
    variant1: false,
    variant2: false,
    variant3: false,
  },
  plpHero: {
    default: true,
    variant1: false,
    variant2: false,
  },
  homePageType: {
    default: true,
    variant1: false,
    variant2: false
  }
})

export const mutations = {
  // pass in which variant you want to run
  // call this method by calling the following in VWO js script
  // $nuxt.$store.dispatch("vwo/startABTest", {name: 'name_of_test_in_state',variant: 'variant1'});

  runVariant(state, payload) {
    Object.entries(state).forEach(e => {
      const [key, value] = e;
      if (payload && key == payload.name) {
        Object.keys(value).forEach(index => {
          state[key][index] = index === payload.variant
        })
      }
    })
  }
}

export const actions = {
  async startABTest({ commit }, payload) {
    commit("runVariant", payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
