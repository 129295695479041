export const state = () => ({
  drawingMenus: [],
  navVisible: false,
  showingMenu: false,
  menuActive: false,
})

export const mutations = {
  showDrawingMenu(state, id) {
    state.drawingMenus[id].open = true
    state.navVisible = true
  },
  hideDrawingMenu(state, id) {
    state.drawingMenus[id].open = false
  },
  addDrawingMenus(state, items) {
    for (let i = 0; i < items; i++) {
      state.drawingMenus.push({
        open: false
      })
    }
  },
  closeAllDrawingMenu(state) {
    for (let i = 0; i < state.drawingMenus.length; i++) {
      state.drawingMenus[i].open = false
      state.navVisible = false
    }
  },
  changeMenuActive(state, val) {
    state.menuActive = val;
  },
  changeShowingMenu(state, val) {
    state.showingMenu = val;
  },
}

export default {
  state,
  mutations,
}