import get from 'lodash/get'
import Memoize, { MemCache } from '@relax/async-utils/memoize'
import { getCollection, getCollections } from './dataLoaders/collections'
import { getContentItem, getBlog, getArticle, getBlogPage, getPage } from './dataLoaders/contentful'
import { getProduct } from './dataLoaders/product'
import { getProducts } from './dataLoaders/products'
import { getProductVariantStock, getCollectionProductStock } from '~/queries'
import { graphql } from '~/utils/graphql'
import { Store } from 'vuex'
import { AxiosInstance } from 'axios'
import {
  StockData,
  ArticleList,
  Blog,
  BlogData,
  Collection,
  CollectionData,
  Product,
} from '~/queries/schemas'
/**
 * define API export interface options
 */
export interface ApiClientOptions {
  $nacelle: any;
  store: Store<any>;
  $axios: AxiosInstance;
  defaultLocale: string;
}
export interface SortOptions {
}
export interface GetItemOptions {
  handle: string;
  locale?: string;
}
export interface GetItemsOptions {
  handles: Array<string>;
  locale?: string;
}
export interface GetCollectionOptions extends GetItemOptions {
  index?: number;
  selectedList?: string;
  sortOptions: SortOptions;
  itemsPerPage?: number | boolean; // FIXME
}
export interface GetBlogOptions {
  blog: Blog;
  locale?: string;
  selectedList?: string;
  startPoint?: number;
}
export interface StockOptions {
  product?: Product;
  collection?: Collection;
}
/**
 * internal data api, use this interface to access *any* data source. we
 * occasionally change upstream providers, this pattern simplifies the process
 * of migration by avoiding global code changes. keep the existing schemas in
 * mind (they are listed at the bottom of this file).
 *
 * ```
 * this.$api.getProduct({ handle })
 * this.$api.getCollection({ handle })
 * ```
 *
 * within headless/nuxt, the api is setup in this way:
 *
 * ```
 * import ApiClient from '~/utils/ApiClient'
 * export default (ctx, inject) => {
 *   // used as $api, the $ is added by nuxt
 *   inject('api', new ApiClient(ctx))
 * }
 * ```
 */
export default class ApiClient {
  $nacelle: any;
  $store: Store<any>;
  $axios: AxiosInstance;
  defaultLocale: string;
  _gqlCache: MemCache;
  _dataLoaderCache: MemCache;

  // helpers
  constructor({ $nacelle, $axios, store, defaultLocale }: ApiClientOptions) {
    this.defaultLocale = defaultLocale || 'en-us'
    this.$nacelle = $nacelle
    this.$store = store
    this.$axios = $axios
    // @link https://gitlab.com/r14c/relax.js/-/blob/main/async-utils/README.md#module_memoize
    // since we can reliably identify products by handle, we can use a cheap
    // identity function here.
    this._gqlCache = Memoize((args: any) => {
      return [
        get(args, '[2][0].query'),
        ...Object.values(get(args, '[2][0].variables'))
      ].join('--')
    })
    // using default identity
    this._dataLoaderCache = Memoize()
  }
  /**
   * memcache wrapper for data loader functions, helps avoid hitting the network
   * for repeated fetches of the same resource.
   */
  _getCached(fn: Function, options: any) {
    return this._dataLoaderCache(
      (args: any) => fn({ ...args, $nacelle: this.$nacelle, $axios: this.$axios }),
      [options],
      1000
    )
  }
  _withDefaultLocale<T>(options: { [index: string]: any, locale?: string }): T | { locale: string } {
    return { ...options, locale: options.locale || this.defaultLocale }
  }
  // data loaders
  getProduct({ handle, locale }: GetItemOptions): Promise<Product> {
    return this._getCached(getProduct, this._withDefaultLocale({ handle, locale }))
  }
  getProducts({ handles, locale }: GetItemsOptions): Promise<Array<Product>> {
    return this._getCached(getProducts, this._withDefaultLocale({ handles, locale }))
  }
  getCollection({
    handle,
    locale,
    itemsPerPage,
    index,
    selectedList,
    sortOptions,
  }: GetCollectionOptions): Promise<CollectionData> {
    return this._getCached(getCollection, this._withDefaultLocale({
      handle,
      locale,
      itemsPerPage: ((itemsPerPage === null) || (itemsPerPage === false))
        ? undefined
        : itemsPerPage || 30,
      index,
      selectedList,
      sortOptions,
    }))
  }
  async getCollectionProducts({
    handle,
    locale,
    itemsPerPage,
    index,
    selectedList,
    sortOptions,
  }: GetCollectionOptions): Promise<Array<Product>> {
    const collection = await this.getCollection({
      handle,
      locale: locale || this.defaultLocale,
      itemsPerPage: ((itemsPerPage === null) || (itemsPerPage === false))
        ? undefined
        : itemsPerPage || 30,
      index,
      selectedList,
      sortOptions,
    })
    return collection && collection.products
  }
  /**
   * @param {object} options
   * @param {string[]} options.collections - collection handles
   * @param {object} options.sortOptions
   * note: sort options apply to each collection individually
   */
  getCollections({
    collections,
    sortOptions
  }: { collections: Array<Collection>, sortOptions: SortOptions }) {
    return this._getCached(getCollections, {
      collections,
      sortOptions,
    })
  }
  /**
   * @param {object} options
   * @param {string} options.handle
   * @param {string} options.locale
   */
  getBlog(options: GetItemOptions): BlogData {
    return this._getCached(getBlog, this._withDefaultLocale(options))
  }
  /**
   * @param {object} options
   * @param {object} options.blog
   * @param {string} options.locale
   * @param {string} [options.selectedList='default']
   * @param {number} [options.startPoint=0]
   */
  getBlogPage(options: GetBlogOptions) {
    return this._getCached(getBlogPage, this._withDefaultLocale(options))
  }
  /**
   * @param {object} options
   * @param {string} options.handle
   * @param {string} options.locale
   */
  getArticle(options: GetItemOptions) {
    return this._getCached(getArticle, this._withDefaultLocale(options))
  }
  /**
   * @param {object} options
   * @param {string} options.handles
   * @param {string} options.locale
   */
  getPage(options: GetItemsOptions) {
    return this._getCached(getPage, this._withDefaultLocale(options))
  }
  /**
   * @param {object} options
   * @param {string} options.handle
   * @param {string} options.locale
   */
  getContentItem(options: GetItemOptions) {
    return this._getCached(getContentItem, this._withDefaultLocale(options))
  }
  // status checking
  /**
   * @param {object} options
   * @param {object} [options.product]
   * @param {object} [options.variant]
   */
  async checkStockAvailable({ product, collection }: StockOptions): Promise<StockData | void> {
    let result = null
    if (product && product.handle) {
      try {
        const q = getProductVariantStock()
        const options = [{
          ...q,
          variables: { productHandle: product.handle }
        }]
        const ttl = 1000 * q.ttl // match the edge cache ttl
        const response = await this._gqlCache(graphql, options, ttl)
        result = get(response, 'data.result') || {}
      } catch (err) {
        console.error(err)
      }
    } else if (collection && collection.handle) {
      try {
        const q = getCollectionProductStock()
        const options = [{
          ...q,
          variables: { collectionHandle: collection.handle }
        }]
        const ttl = 1000 * q.ttl // match the edge cache ttl
        const response = await this._gqlCache(graphql, options, ttl)
        result = get(response, 'data.result') || {}
      } catch (err) {
        console.error(err)
      }
    }
    return result
  }
  // getters
  getSelectedBlogList(blog: Blog, selectedList = 'default') {
    let articleList: ArticleList | any = {}
    if (
      blog &&
      blog.articleLists &&
      blog.articleLists.length
    ) {
      for (let list of blog.articleLists) {
        if (list.slug === selectedList) {
          articleList = list
          break
        }
      }
    }
    return (articleList && articleList.handles) ? articleList.handles : []
  }
}
